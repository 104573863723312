<template>
  <div class="main">
    <div class="login-header"></div>
    <div class="login-container">
      <img src="./../assets/logo-login.png" alt="logo safe" />
      <div class="login-form">
        <h1>Password lost</h1>
        <form @submit.prevent="onSubmit">
            <div class="row">
              <!-- Email -->
              <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
                  <input class="form-control" placeholder="Email*" type="email" v-model="v$.form.email.$model">
                  <!-- error message -->
                  <template v-if="v$.form.email.$errors.length > 0">                
                      <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'required'">This field is required.</div>
                      <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'email'">Invalid email format.</div>
                      <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'max'">{{v$.form.email.$errors[0].$params.max}} caracters maximum allowed.</div>
                  </template>
              </div>
            </div>

          <!-- Submit Button -->
          <div class="row-btn">
              <input type="submit" :disabled="v$.form.$invalid" class="btn" value="Send" />
          </div>
          <!-- Errors -->
        </form>
        <div class="notices errors" v-if="errors">An error has occured : <br />{{ errors}}</div>
        <div class="notices success" v-if="success">{{ success}}</div>
        <router-link :to="{name : 'Home'}" class="back">Back</router-link>
      </div>
    </div>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, maxLength } from '@vuelidate/validators';
import axios from 'axios';

export default {
  name: 'PasswordLost',
    data() {
        return {
            form : {
                email : "",
            },
            success : false,
            errors : null,
            errCheck : false
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            form : {   
                email: {
                    required, email, max: maxLength(150) 
                },                
            }
        }        
    },
    methods: {
        async onSubmit() {

            const isFormCorrect = await this.v$.$validate();

            if (!isFormCorrect) return;

            this.errors = null

            let data = {
              "email" : this.form.email,
            }

            let config = {
                validateStatus: () => true,
            };
            let vm = this

            axios
                .post(`${this.$store.state.apiUrl}/auth/password/resetLink`, data, config)
                .then(function (res) {
                    if(res.status != 200) {
                      vm.errors = res.data.errors;
                    }else {
                        vm.success = res.data.message;
                        vm.email = null;
                    }
         
                })     
        },
    },
    mounted() {
      localStorage.removeItem("_safeToken");
    }
    
}
</script>

<style lang="scss" scoped>
  .login-header {
    height:20px;
    width:100%;
    background:$mainColor;
  }

  .login-container {
    min-height: calc(100vh - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    flex-direction: column;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  form {
    width:300px;
    margin-left: -20px;
  }

  input {
    text-align: center;
    width:100%;
  }

  ::placeholder {
    text-transform: uppercase;
  }

  .btn {
    width:calc(100% + 23px);
    padding:10px;
    text-transform: uppercase;
  }

  .notices{
    width: 400px;
    margin:20px auto;
  }

  h1 {
    color:#fff;
    text-align: center;
    font-size:25px;
    text-transform: uppercase;
    font-weight: 300;
    margin:40px auto 20px auto;
  }

  .main {
    margin: 0;
  }

    .row-btn {
    display:block;
  }
</style>